<div class="avatar-container">
  <ng-container *ngIf="pictureImg$ | async as img; else iconRef">
    <div
      class="person__avatar person__avatar--image"
      [ngStyle]="{ 'background-image': 'url(' + img + ')' }"
      (error)="onImgNotLoaded()"
      [hidden]="imgNotLoaded === true"
    ></div>
  </ng-container>
  <ng-template #iconRef>
    <ng-container *ngIf="icon; else initials">
      <div class="person__avatar person__avatar--icon">
        <fa-icon [icon]="icon"></fa-icon>
      </div>
    </ng-container>
  </ng-template>
  <ng-template #initials>
    <div class="person__avatar person__avatar--initials">
      {{ (fullName | formatInitials) || '?' }}
    </div>
  </ng-template>
</div>
<div class="text-container" [class.user-unknown]="!fullName" *ngIf="fullNameVisible">
  <ng-container *ngIf="fullName; else noFullName">
    <ng-container
      [ngTemplateOutlet]="fullnameRef.template"
      [ngTemplateOutletContext]="{ $implicit: formattedFullName }"
      *ngIf="fullnameRef; else fullNameText"
    />
    <ng-template #fullNameText>
      {{ formattedFullName }}
    </ng-template>
  </ng-container>
  <ng-template #noFullName>
    <ng-container [ngTemplateOutlet]="noFullnameRef.template" *ngIf="noFullnameRef; else noFullNameTextTempl" />
    <ng-template #noFullNameTextTempl>
      {{ noFullNameText }}
    </ng-template>
  </ng-template>
</div>
