import { booleanAttribute, ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { StickerDirective } from '@nexuzhealth/shared-ui-toolkit/sticker';

@Component({
  selector: 'nxh-stack',
  templateUrl: './stack.component.html',
  styleUrls: ['./stack.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [
    {
      directive: StickerDirective,
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['stickTopLeft', 'stickTopRight', 'stickBottomRight', 'stickBottomLeft'],
    },
  ],
})
export class StackComponent {
  @Input() direction: 'row' | 'col' = 'row';
  /**
   * Specifies the space to be put between the stacked items. Allowed values are:
   * <ul>
   *   <li>'xs': 0.25rem</li>
   *   <li>'sm': 0.5rem</li>
   *   <li>'md': 0.75rem</li>
   *   <li>'lg': 1rem</li>
   *   <li>'xl': 1.5rem</li>
   *   <li>'none': 0</li>
   * </ul>
   */
  @Input() gap: 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' = 'xs';
  @Input() justify: 'start' | 'end' | 'space-between';
  /**
   * @deprecated Align is an existing albeit deprecated HTML attribute. While it is deprecated, chrome is still
   * supporting it, causing all children of an nxh-stack to either float to the left or the right.
   * Use alignItems in stead.
   *
   * More info: https://www.w3.org/TR/html401/present/graphics.html#:~:text=The%20align%20attribute%20for%20objects,to%20the%20current%20left%20margin.
   */
  @Input() set align(align: 'start' | 'end' | 'center') {
    this.alignItems = align;
  }
  @Input() alignItems: 'start' | 'end' | 'center' = 'center';
  @HostBinding('class.stack--nowrap') @Input({ transform: booleanAttribute }) nowrap = false;

  @HostBinding('class.stack--row') get isRow() {
    return this.direction === 'row';
  }

  @HostBinding('class.stack--col') get isCol() {
    return this.direction === 'col';
  }

  @HostBinding('class.stack--justify-end') get isJustifyEnd() {
    return this.justify === 'end';
  }

  @HostBinding('class.stack--justify-space-between') get isJustifySpaceBetween() {
    return this.justify === 'space-between';
  }

  @HostBinding('class.stack--gap-none') get none() {
    return this.gap === 'none';
  }

  @HostBinding('class.stack--gap-xs') get xs() {
    return this.gap === 'xs';
  }

  @HostBinding('class.stack--gap-sm') get sm() {
    return this.gap === 'sm';
  }

  @HostBinding('class.stack--gap-md') get md() {
    return this.gap === 'md';
  }

  @HostBinding('class.stack--gap-lg') get lg() {
    return this.gap === 'lg';
  }

  @HostBinding('class.stack--gap-xl') get xl() {
    return this.gap === 'xl';
  }

  @HostBinding('class.stack--align-start') get alignStart() {
    return this.alignItems === 'start';
  }

  @HostBinding('class.stack--align-end') get alignEnd() {
    return this.alignItems === 'end';
  }
}
