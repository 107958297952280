import {
  AfterContentInit,
  AfterViewInit,
  booleanAttribute,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { guid } from '@datorama/akita';
import { addDataTestAttributes, DataTestDirective } from '@nexuzhealth/shared-tech-feature-e2e';
import { Focusable, FOCUSSABLE } from '@nexuzhealth/shared-ui-toolkit/focus';
import { ValidationErrorsComponent } from 'ngx-valdemort';
import { INVALIDABLE_CONTROL } from '../../../shared/form-helper.domain';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'nh-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [{ provide: FOCUSSABLE, useExisting: forwardRef(() => CheckboxComponent) }],
})
export class CheckboxComponent implements ControlValueAccessor, AfterViewInit, AfterContentInit, Focusable {
  @Input() label!: string;
  @Input() id = guid();
  @Input() required = false;
  @Input() switch = false;
  @HostBinding('class.shrink-when-no-label')
  @Input()
  shrinkWhenNoLabel = false;

  @ContentChild(ValidationErrorsComponent) errorsComponent: ValidationErrorsComponent;
  @ViewChild('input', { static: true }) input: ElementRef<HTMLInputElement>;

  disabled = false;
  isChecked = false;

  @HostBinding('class')
  classes = INVALIDABLE_CONTROL;

  @HostBinding('class.margin-bottom-enabled')
  @Input()
  public hasBottomMargin = true;

  onChange!: (_) => void;
  onTouched!: (_) => void;

  @HostListener('mousedown', ['$event'])
  mousedown(event: MouseEvent) {
    event.preventDefault();
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    @Optional() private dataTestDirective: DataTestDirective,
    @Self() @Optional() private ngControl?: NgControl,
  ) {
    if (ngControl) ngControl.valueAccessor = this;
  }

  ngAfterViewInit(): void {
    addDataTestAttributes(this.dataTestDirective?.nxhDataTest || 'checkbox', {
      element: this.input.nativeElement,
      suffix: '_checkbox',
    });
  }

  writeValue(obj: boolean): void {
    this.isChecked = obj;
    this.changeDetectorRef.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChanged($event) {
    this.isChecked = $event && $event.target && $event.target.checked;
    this.onChange(this.isChecked);
  }

  ngAfterContentInit(): void {
    if (!this.ngControl) {
      return;
    }

    if (!this.errorsComponent) {
      return;
    }

    if (!this.errorsComponent.label) {
      this.errorsComponent.label = this.label;
    }
    this.errorsComponent.control = this.ngControl.control;
  }

  setFocus() {
    this.input.nativeElement.focus();
  }
}
