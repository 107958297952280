import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, inject, ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedTechFeatureE2eModule } from '@nexuzhealth/shared-tech-feature-e2e';
import { SharedUtilI18nModule } from '@nexuzhealth/shared-util';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { DisplayMode, ValdemortConfig, ValdemortModule } from 'ngx-valdemort';
import { IconsModule } from '@nexuzhealth/shared-ui-toolkit/icons';
import { ButtonModule } from '@nexuzhealth/shared-ui-toolkit/button';
import { EMPTY } from 'rxjs';
import { ButtonLoadingDirective } from './components/button-loading.directive';
import { CheckboxGroupComponent } from './components/controls/checkbox-group/checkbox-group.component';
import { CheckboxComponent } from './components/controls/checkbox/checkbox.component';
import { RadioGroupComponent } from './components/controls/radio-group/radio-group.component';
import { RadioComponent } from './components/controls/radio/radio.component';
import { CancelFormButtonComponent } from './components/form-helper/cancel-form-button/cancel-form-button.component';
import { ControlComponent } from './components/form-helper/control/control.component';
import { DefaultErrorsComponent } from './components/form-helper/default-errors/default-errors.component';
import { FieldsetComponent } from './components/form-helper/fieldset/fieldset.component';
import { FormHelperDirective } from './components/form-helper/form-helper.directive';
import { GroupComponent } from './components/form-helper/group/group.component';
import { InputGroupComponent } from './components/form-helper/group/input-group.component';
import { MarkForCheckOnSubmitDirective } from './components/form-helper/mark-for-check-on-submit.directive';
import { SubmitFormButtonComponent } from './components/form-helper/submit-form-button/submit-form-button.component';
import { PasswordInputComponent } from './components/password-input/password-input.component';
import { WarningMessageComponent } from './components/warning-message/warning-message.component';
import { SkipDirtyCheckDirective } from './components/form-helper/skip-dirty-check.directive';
import { OutsideLabelDirective } from './components/controls/radio/outside-label.directive';
import { ConnectFormHelperDirective } from './components/form-helper/connect-form-helper.directive';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IconsModule,
    ValdemortModule,
    SharedUtilI18nModule,
    NgbTooltipModule,
    SharedTechFeatureE2eModule,
    ButtonModule,
  ],
  declarations: [
    ButtonLoadingDirective,
    CancelFormButtonComponent,
    CheckboxComponent,
    CheckboxGroupComponent,
    ControlComponent,
    DefaultErrorsComponent,
    FieldsetComponent,
    FormHelperDirective,
    PasswordInputComponent,
    RadioComponent,
    RadioGroupComponent,
    SubmitFormButtonComponent,
    WarningMessageComponent,
    MarkForCheckOnSubmitDirective,
    GroupComponent,
    InputGroupComponent,
    SkipDirtyCheckDirective,
    OutsideLabelDirective,
    ConnectFormHelperDirective,
  ],
  exports: [
    ButtonLoadingDirective,
    CancelFormButtonComponent,
    CheckboxComponent,
    CheckboxGroupComponent,
    ControlComponent,
    DefaultErrorsComponent,
    FieldsetComponent,
    FormHelperDirective,
    PasswordInputComponent,
    RadioComponent,
    RadioGroupComponent,
    ReactiveFormsModule,
    SubmitFormButtonComponent,
    ValdemortModule,
    WarningMessageComponent,
    MarkForCheckOnSubmitDirective,
    GroupComponent,
    InputGroupComponent,
    SkipDirtyCheckDirective,
    OutsideLabelDirective,
    ConnectFormHelperDirective,
  ],
})
export class NxhFormsModule {}
